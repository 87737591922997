import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthService } from '../core/auth.service';

@Injectable()
export class UserService {
  constructor(
    private http: HttpClient
    ) { }
  
    private apiPath = 'api/user';
  
  setUserCacheAfterLogin(accessToken: string): Observable<any> {
      var reqHeader = new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + accessToken
      });
      return this.http.get<any>(this.apiPath + '/setUserCacheAfterLogin',{ headers: reqHeader })
        .pipe(
          map(response => response),
          catchError((e: HttpErrorResponse) => of(e)),
        ); 
  }
  
  getUserMenu(accessToken: string): Observable<any> {
      var reqHeader = new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + accessToken
      });
      return this.http.get<any>(this.apiPath + '/GetUserMenu',{ headers: reqHeader })
        .pipe(
          map(response => response),
          catchError((e: HttpErrorResponse) => of(e)),
        ); 
  }
  
  refreshUserCache(accessToken: string, oldAccessToken: string): Observable<any> {
      var reqHeader = new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + accessToken
      });
      var body = JSON.stringify(oldAccessToken);
      return this.http.post<any>(this.apiPath + '/refreshUserCache', body, { headers: reqHeader })
        .pipe(
          map(response => response),
          catchError((e: HttpErrorResponse) => of(e)),
        );      
  }
}
