import { AuthConfig } from 'angular-oauth2-oidc';
import { environment } from 'src/environments/environment';

export const authConfig: AuthConfig = {
  issuer: environment.issuer,
  clientId: environment.clientId, // The "Auth Code + PKCE" client
  responseType: environment.responseType,
  redirectUri: environment.redirectUri,
  silentRefreshRedirectUri: environment.silentRefreshRedirectUri,
  scope: environment.scope, // Ask offline_access to support refresh token refreshes
  useSilentRefresh: environment.useSilentRefresh, // Needed for Code Flow to suggest using iframe-based refreshes
  silentRefreshTimeout: environment.silentRefreshTimeout, // For faster testing
  timeoutFactor: environment.timeoutFactor, // For faster testing
  sessionChecksEnabled: environment.sessionChecksEnabled,
  showDebugInformation: environment.showDebugInformation, // Also requires enabling "Verbose" level in devtools
  clearHashAfterLogin: environment.clearHashAfterLogin, // https://github.com/manfredsteyer/angular-oauth2-oidc/issues/457#issuecomment-431807040,
  nonceStateSeparator : environment.nonceStateSeparator // Real semicolon gets mangled by Duende ID Server's URI encoding
}