export const environment = {
  production: true,
  baseUrl: "https://private.covprotezionecivile.it/",

  issuer: "https://private.covprotezionecivile.it/idsrv",
  clientId: "Prod", // The "Auth Code + PKCE" client
  responseType: "id_token token",
  redirectUri: "https://private.covprotezionecivile.it/",
  silentRefreshRedirectUri: "https://private.covprotezionecivile.it/silent-refresh.html",
  scope: "openid profile email api", // Ask offline_access to support refresh token refreshes
  useSilentRefresh: true, // Needed for Code Flow to suggest using iframe-based refreshes
  silentRefreshTimeout: 5000, // For faster testing
  timeoutFactor: 0.25, // For faster testing
  sessionChecksEnabled: true,
  showDebugInformation: true, // Also requires enabling "Verbose" level in devtools
  clearHashAfterLogin: true, // https://github.com/manfredsteyer/angular-oauth2-oidc/issues/457#issuecomment-431807040,
  nonceStateSeparator : "semicolon", // Real semicolon gets mangled by Duende ID Server's URI encoding
  registrationUrl : 'https://forms.gle/6AjonrxW8daZgc4o9'
};
