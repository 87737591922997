import { Component } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import { AuthService } from '../core/auth.service';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-menu',
  templateUrl: 'app-menu.component.html',
  styleUrls: ['app-menu.component.css']
})
export class AppMenuComponent {
  isAuthenticated$: Observable<boolean>;
  my_menu: any;
  
  objectKeys = Object.keys;
  
  constructor(
    private oauthService: OAuthService,
    private authService: AuthService,
    private userService: UserService
    ) {
    this.isAuthenticated$ = authService.isAuthenticated$;    
      this.isAuthenticated$.subscribe(isAuthenticatedResult => {
        if(isAuthenticatedResult){   
          if(!this.my_menu || this.my_menu === undefined)       
          this.userService.getUserMenu(this.oauthService.getAccessToken()).subscribe((response) => {
            this.my_menu = response;
          });
        }
      })      
    }  

  login() {
    this.authService.login();
  }
  register() {
    window.location.href = environment.registrationUrl;
  }
  logout() {
    this.authService.logout();
  }

  get email(): string {
    return this.authService.identityClaims
    ? (this.authService.identityClaims as any)['name']
    : '-';
  }
}
