/* eslint-disable brace-style */
/* eslint-disable max-len */

import { Component, OnInit } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { ToastrService } from 'ngx-toastr';
import { filter, Observable, take, takeUntil } from 'rxjs';

import { AuthService } from './core/auth.service';
import { WebGisService } from './web-sor/services/webgis.service';

@Component({
  selector: 'app-root',
  template: `<div class="container-fluid root-element">
    <app-menu></app-menu>
    <div class="container-fluid mt-2">   
      <router-outlet></router-outlet>
      <div class="custom-footer">
      <hr>
            © Copyright 2022 |  C.O.V. Coordinamento delle Organizzazioni di Protezione Civile <a href="#" target="_blank">C.O.V.</a>     |   All Rights Reserved           
      <div>   
      <!--
      <p>You can <a routerLink="/url-without-route">go to a url without a route</a> to see the fallback route.</p>
      AccessToken: <code class="break-all">{{accessToken}}</code>
      <button class="btn btn-warning mr-1" (click)='refresh()'>force silent refresh</button>
       -->
    </div>
  </div>`,
})
export class AppComponent implements OnInit{
  isAuthenticated$: Observable<boolean>;
  isDoneLoading$: Observable<boolean>;
  canActivateProtectedRoutes$: Observable<boolean>;
  toastSent: boolean = false;

  constructor(
    private authService: AuthService,
    private toastr: ToastrService,
    private emergenzeService: WebGisService,
    private oAuthService: OAuthService
  ) {
    this.isAuthenticated$ = this.authService.isAuthenticated$;
    this.isDoneLoading$ = this.authService.isDoneLoading$;
    this.canActivateProtectedRoutes$ = this.authService.canActivateProtectedRoutes$;
  }
  ngOnInit(): void {

    this.isAuthenticated$.pipe(
      takeUntil(this.isAuthenticated$.pipe(filter(() => this.toastSent === true))),
    ).subscribe(isAuth=>{      
      if(isAuth){
        this.emergenzeService.getAllertaMeteoIdroGeo(this.oAuthService.getAccessToken()).subscribe(data => {
          data.forEach((allerta: any) => {        
            this.toastr.info(`Il Centro Funzionale Regionale ha emesso un allertamento del sistema regionale di Protezione Civile con validità fino al ${this.dateFormatter(allerta.dataTermine,'datetime')}. 
                                <br> <a href="${allerta.path}" target="_blank">Clicca quì</a> per visualizzare il documento.`, `[${this.dateFormatter(allerta.date,'date')}] Allertamento del sistema regionale di Protezione Civile - Allerta ${allerta.categoria.name}`, {
              enableHtml:true,
              timeOut: 60000,
              extendedTimeOut: 10000,
              closeButton: true,
              progressBar: true,
              tapToDismiss: false,
              positionClass: "toast-bottom-full-width",
              toastClass: `ngx-toastr ${allerta.categoria.class}`
            });
          });
        });  
        this.toastSent = true;    
      }
    })
  }

  dateFormatter(date: any, format: string) {
    var dateParts = new Date(date);
    switch(format){
      case 'date':
        return `${dateParts.toLocaleDateString()}`;
      default:
        return `${dateParts.toLocaleString()}`;
    }
  }   
  // login() { this.authService.login(); }
  // logout() { this.authService.logout(); }
  refresh() { this.authService.refresh(); }
  // reload() { window.location.reload(); }
  // clearStorage() { localStorage.clear(); }

  // logoutExternally() {
  //   window.open(this.authService.logoutUrl);
  // }

  // get hasValidToken() { return this.authService.hasValidToken(); }
  get accessToken() { return this.authService.accessToken; }
  // get refreshToken() { return this.authService.refreshToken; }
  // get identityClaims() { return this.authService.identityClaims; }
  // get idToken() { return this.authService.idToken; }
}
