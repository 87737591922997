import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Emergenza } from '../models/emergenza';
import { TokenStorageService } from './token-storage.service';
import { UserService } from 'src/app/services/user.service';
import { OAuthService } from 'angular-oauth2-oidc';
import { Idrante } from '../models/idrante';

const API_URL = 'web/v1/evento/';
@Injectable({
  providedIn: 'root'
})
export class WebGisService {
  constructor(
    private http: HttpClient
    ) { }

  private apiPath = 'api/webgis';

  public getEvents(accessToken: string): Observable<Emergenza[]> {  
        var reqHeader = new HttpHeaders({ 
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + accessToken
        });
        return this.http.get<any>(this.apiPath + '/GetEventsFromRegioneLazio',{ headers: reqHeader });
  }  
  public getEvent(accessToken: string, id: any): Observable<Emergenza> {
    var reqHeader = new HttpHeaders({ 
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + accessToken
    });
    return this.http.get<Emergenza>(this.apiPath + '/GetEventFromRegioneLazio?eventId=' + id, {headers: reqHeader, responseType: 'json' });
  }  
  public getIdranti(accessToken: string): Observable<Idrante[]> {  
        var reqHeader = new HttpHeaders({ 
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + accessToken
        });
        return this.http.get<any>(this.apiPath + '/GetIdranti',{ headers: reqHeader });
  }   
  public getAllertaMeteoIdroGeo(accessToken: string): Observable<any> {  
        var reqHeader = new HttpHeaders({ 
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + accessToken
        });
        return this.http.get<any>(this.apiPath + '/GetAllertaMeteoIdroGeo',{ headers: reqHeader });
  } 

  public getStoricoAllerteMeteoIdroGeo(accessToken: string): Observable<any> {  
    var reqHeader = new HttpHeaders({ 
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + accessToken
    });
    return this.http.get<any>(this.apiPath + '/GetStoricoAllerteMeteoIdroGeo',{ headers: reqHeader });
  } 

  public getComunicatiStampaFromDipartimentoNazionale(accessToken: string): Observable<any> {  
    var reqHeader = new HttpHeaders({ 
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + accessToken
    });
    return this.http.get<any>(this.apiPath + '/GetComunicatiStampaFromDipartimentoNazionale',{ headers: reqHeader });
  } 
  public getTerremotiFromINGV(accessToken: string): Observable<any> {  
    var reqHeader = new HttpHeaders({ 
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + accessToken
    });
    return this.http.get<any>(this.apiPath + '/GetTerremotiFromINGV',{ headers: reqHeader });
  } 
  public GetTerremotiList(accessToken: string): Observable<any> {  
    var reqHeader = new HttpHeaders({ 
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + accessToken
    });
    return this.http.get<any>(this.apiPath + '/GetTerremotiList',{ headers: reqHeader });
  } 
}