<nav class="navbar navbar-expand-sm navbar-light bg-light">
  <!-- Toggler/collapsibe Button -->
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
    <span class="navbar-toggler-icon"></span>
  </button>  
  <!-- Navbar links -->
  <div class="collapse navbar-collapse" id="collapsibleNavbar">
  <ul class="navbar-nav mr-auto">
      <li class="nav-item">
        <a class="nav-link" routerLinkActive="active" routerLink="public/home">Home</a>
      </li>
      <ng-container *ngIf="(isAuthenticated$ | async) === true">
        <li class="nav-item">
          <a class="nav-link" routerLink="public/dashboard">Mappe WebGIS</a>
        </li>
        <li 
        *ngFor="let mainItem of (my_menu && objectKeys(my_menu))" 
        [ngClass]="{ 'nav-item': true, 'dropdown': (my_menu[mainItem].subAppMenuItems != null)}">
          <a [ngClass]="{ 'nav-link': true, 'dropdown-toggle': (my_menu[mainItem].subAppMenuItems != null)}"
            routerLink="{{ my_menu[mainItem].appMenuUri }}"
            id="navbarDropdown"
            role="button"
            [attr.data-toggle]="(my_menu[mainItem].subAppMenuItems != null) ? 'dropdown' : null"
            aria-haspopup="true"
            aria-expanded="false"
          >
            {{ my_menu[mainItem].appMenuName }}
          </a>
          <div *ngIf="my_menu[mainItem].subAppMenuItems != null" class="dropdown-menu" aria-labelledby="navbarDropdown" >
            <a class ="dropdown-item"
            *ngFor="let subItem of (my_menu[mainItem].subAppMenuItems && my_menu[mainItem].subAppMenuItems)"
            routerLink="{{ subItem.appMenuUri }}"
            >{{ subItem.appMenuName }}</a>              
          </div>
        </li>      
    </ng-container>  
    </ul>
    <button class="btn btn-sm btn-default" style="color:white;" (click)="register()" *ngIf="(isAuthenticated$ | async) === false">Registrazione</button>
    <button class="btn btn-sm btn-default" style="color:white;" (click)="login()" *ngIf="(isAuthenticated$ | async) === false">Log in</button>
    <span *ngIf="isAuthenticated$ | async" id="email">{{email}}</span>
    <button *ngIf="isAuthenticated$ | async" href="#" (click)="logout()" class="btn btn-link">(log out)</button>
  </div>
  </nav>